import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Neon Nostalgia Bar & Lounge
			</title>
			<meta name={"description"} content={"Relive the '80s glow at Neon Nostalgia Bar, where vintage vibes meet modern nights"} />
			<meta property={"og:title"} content={"Home | Neon Nostalgia Bar & Lounge"} />
			<meta property={"og:description"} content={"Relive the '80s glow at Neon Nostalgia Bar, where vintage vibes meet modern nights"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12:33:06.409Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride" />
		</Components.Header>
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(36, 36, 36, 0.4) 0%,rgba(28, 28, 28, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/counter_1.jpg?v=2023-10-24T12:33:06.392Z) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 100px 0px 100px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Neon Nostalgia{" "}
					<br />
					Bar & Lounge
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 50px 0px 50px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Welcome to Neon Nostalgia Bar & Lounge – where the best vibes of the '80s meet today's friendly spirit. Whether you're looking for a cozy corner to catch up with old friends, or you’re hoping to strike up a conversation with a fellow lover of yesteryears, you've found the right spot.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-orange"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						sm-text-align="center"
					>
						Visit Us
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="block"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--orange" font="--lead" md-margin="0px 0px 20px 0px">
					The Experience
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					About Us
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Every inch of our bar resonates with the fun and quirky era of the '80s. Our walls are adorned with iconic neon art and vintage posters that give you a genuine throwback feeling. Remember those classic jukeboxes? We’ve got one. Belt out your favourite retro tunes and take a trip down memory lane.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" background="--color-darkL2" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/people_bar_active_1.jpg?v=2023-10-24T12:33:06.438Z"
					display="block"
					max-width="330px"
					width="100%"
					height="330px"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/people_bar_active_1.jpg?v=2023-10-24T12%3A33%3A06.438Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/people_bar_active_1.jpg?v=2023-10-24T12%3A33%3A06.438Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/people_bar_active_1.jpg?v=2023-10-24T12%3A33%3A06.438Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/people_bar_active_1.jpg?v=2023-10-24T12%3A33%3A06.438Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/people_bar_active_1.jpg?v=2023-10-24T12%3A33%3A06.438Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/people_bar_active_1.jpg?v=2023-10-24T12%3A33%3A06.438Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/people_bar_active_1.jpg?v=2023-10-24T12%3A33%3A06.438Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					🍸 Signature Drinks
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					Our bar serves a range of nostalgic drinks, some with a twist! Ever tried a neon-colored cocktail? Or perhaps a drink inspired by an '80s pop song? Our talented bartenders love crafting unique beverages that are not just a treat for your taste buds but also a conversation starter.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					🗣 Chatty Bartenders
				</Text>
				<Text font="--base" color="--light" margin="40px 0px 0px 0px">
					Ever just wanted to sit at the bar and talk about everything and anything? Our bartenders are not just experts in mixing drinks – they're great conversationalists. Dive deep into '80s trivia, discuss the latest sports match, or just share a laugh. They’re always ready with a story or two.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
					🕺 Weekend Flashback Nights
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					Every weekend, we host Flashback Nights. Groove to the beats of the '80s with a modern twist. It's the perfect blend of nostalgia and today.
				</Text>
			</Box>
			<Box
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/sign_1.jpg?v=2023-10-24T12:33:06.420Z"
					display="block"
					max-width="330px"
					width="100%"
					height="330px"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/sign_1.jpg?v=2023-10-24T12%3A33%3A06.420Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/sign_1.jpg?v=2023-10-24T12%3A33%3A06.420Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/sign_1.jpg?v=2023-10-24T12%3A33%3A06.420Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/sign_1.jpg?v=2023-10-24T12%3A33%3A06.420Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/sign_1.jpg?v=2023-10-24T12%3A33%3A06.420Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/sign_1.jpg?v=2023-10-24T12%3A33%3A06.420Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/sign_1.jpg?v=2023-10-24T12%3A33%3A06.420Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_1.jpg?v=2023-10-24T12:33:06.431Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_1.jpg?v=2023-10-24T12%3A33%3A06.431Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_1.jpg?v=2023-10-24T12%3A33%3A06.431Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_1.jpg?v=2023-10-24T12%3A33%3A06.431Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_1.jpg?v=2023-10-24T12%3A33%3A06.431Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_1.jpg?v=2023-10-24T12%3A33%3A06.431Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_1.jpg?v=2023-10-24T12%3A33%3A06.431Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_1.jpg?v=2023-10-24T12%3A33%3A06.431Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
				justify-content="space-between"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_3.jpg?v=2023-10-24T12:33:06.387Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						object-position="0%"
						srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_3.jpg?v=2023-10-24T12%3A33%3A06.387Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_3.jpg?v=2023-10-24T12%3A33%3A06.387Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_3.jpg?v=2023-10-24T12%3A33%3A06.387Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_3.jpg?v=2023-10-24T12%3A33%3A06.387Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_3.jpg?v=2023-10-24T12%3A33%3A06.387Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_3.jpg?v=2023-10-24T12%3A33%3A06.387Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_3.jpg?v=2023-10-24T12%3A33%3A06.387Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_8.jpg?v=2023-10-24T12:33:06.402Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_8.jpg?v=2023-10-24T12%3A33%3A06.402Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_8.jpg?v=2023-10-24T12%3A33%3A06.402Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_8.jpg?v=2023-10-24T12%3A33%3A06.402Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_8.jpg?v=2023-10-24T12%3A33%3A06.402Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_8.jpg?v=2023-10-24T12%3A33%3A06.402Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_8.jpg?v=2023-10-24T12%3A33%3A06.402Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/tech_8.jpg?v=2023-10-24T12%3A33%3A06.402Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});